import React, { createContext, useCallback, useContext, useEffect, useRef, useState, } from 'react';
import { AiTextIcon, TranscriptionIcon } from '@marvelapp/ballpark-icons';
import { BtwTabs } from './BtwTabs';
import { BtwText } from './BtwText';
import { CopyToClipboardButton } from './CopyToClipboardButton';
import { CtaMessage } from './CtaMessage';
import { Pin } from './Pin';
import { ScrollArea } from './ScrollArea';
import { Stack } from './Stack';
import { cn } from './utils';
const TAB = {
    Transcription: 'Transcription',
    Summary: 'Summary',
};
const TranscriptionContext = createContext({});
function Root(props) {
    const { children, height, actions } = props;
    const childrenArray = React.Children.toArray(children);
    const hasSummary = childrenArray.some((child) => React.isValidElement(child) && child.type === SummaryTab);
    const hasTranscription = childrenArray.some((child) => React.isValidElement(child) && child.type === TranscriptionTab);
    const scrollAreaViewportRef = useRef(null);
    const lastActiveIndex = useRef(-1);
    const scrollItemIntoView = useCallback((element, index) => {
        if (!element || !scrollAreaViewportRef.current) {
            return;
        }
        const container = scrollAreaViewportRef.current;
        lastActiveIndex.current = index;
        const elementTop = element.offsetTop;
        const topPadding = 10;
        container.scrollTo({
            top: elementTop - topPadding,
            behavior: 'smooth',
        });
    }, []);
    // Tracking whether the transcription container is scrollable,
    // so we can adjust the copy button positioning for each line
    const [isScrollable, setIsScrollable] = useState(false);
    useEffect(() => {
        const container = scrollAreaViewportRef === null || scrollAreaViewportRef === void 0 ? void 0 : scrollAreaViewportRef.current;
        if (!container)
            return;
        const checkIfScrollable = () => {
            const { clientHeight, scrollHeight } = container;
            setIsScrollable(scrollHeight > clientHeight);
        };
        // Initial check
        checkIfScrollable();
        // Adding a ResizeObserver to update state when viewport size changes
        const resizeObserver = new ResizeObserver(() => {
            checkIfScrollable();
        });
        resizeObserver.observe(container);
        // Cleanup
        return () => {
            resizeObserver.disconnect();
        };
    }, [scrollAreaViewportRef, hasTranscription]);
    const contextValue = {
        onScrollIntoView: scrollItemIntoView,
        scrollAreaViewportRef,
        isScrollable,
    };
    return (React.createElement(TranscriptionContext.Provider, { value: contextValue },
        React.createElement(Stack, { width: "full", asChild: true, "data-testid": "transcription" },
            React.createElement(BtwTabs.Root, { defaultValue: TAB.Transcription },
                React.createElement(BtwTabs.List, { className: "relative z-10 w-full", "aria-label": "Transcription" },
                    React.createElement(BtwTabs.Trigger, { leadingIcon: React.createElement(TranscriptionIcon, null), size: "intermediate", value: TAB.Transcription, variant: "ghost" }, TAB.Transcription),
                    hasSummary && (React.createElement(BtwTabs.Trigger, { disabled: !hasSummary, leadingIcon: React.createElement(AiTextIcon, null), size: "intermediate", value: TAB.Summary, variant: "ghost" }, TAB.Summary)),
                    actions && React.createElement("div", { className: "ml-auto" }, actions)),
                hasTranscription ? (React.createElement(ScrollArea.Root, { style: { height } },
                    React.createElement(ScrollArea.Viewport, { ref: scrollAreaViewportRef },
                        React.createElement("div", { className: "h-4" }),
                        children,
                        React.createElement("div", { className: "h-4" }),
                        React.createElement("div", { className: "absolute left-0 right-0 top-0 h-4 bg-gradient-to-b from-gray-50 to-transparent" }),
                        React.createElement("div", { className: "absolute bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-gray-50 to-transparent" })),
                    React.createElement(ScrollArea.Scrollbar, { orientation: "horizontal" }),
                    React.createElement(ScrollArea.Scrollbar, { orientation: "vertical" }))) : (React.createElement("div", { style: { height }, className: "w-full pt-4" }, children))))));
}
export function Item(props) {
    const { isActive, name, nameVariant, text, timestamp, index } = props;
    const { onScrollIntoView, isScrollable } = useContext(TranscriptionContext);
    const ref = React.useRef(null);
    const prevActiveRef = useRef(isActive);
    useEffect(() => {
        const wasActive = prevActiveRef.current;
        prevActiveRef.current = isActive;
        // We check both isActive && !wasActive to ensure we only scroll when an item
        // becomes active, not when it remains active or becomes inactive. This prevents
        // unnecessary scroll operations on every re-render while an item is active.
        if (isActive &&
            !wasActive &&
            onScrollIntoView &&
            typeof index === 'number') {
            requestAnimationFrame(() => {
                onScrollIntoView(ref.current, index);
            });
        }
    }, [isActive, onScrollIntoView, index, text]);
    return (React.createElement(Stack, { ref: ref, "data-testid": "segment", className: cn('p-2', 'group', 'relative', 'rounded-lg', 'duration-300', 'ease-smooth', 'transition-combined', 'hover:bg-gray-600/10', {
            'bg-gray-100': isActive,
        }), direction: "row", gap: "1", width: "full", "data-active": isActive },
        React.createElement("div", { className: "w-14 py-1" },
            React.createElement(BtwText, { asChild: true, variant: isActive ? 'primary' : 'secondary', leading: "normal", size: "13", weight: isActive ? 'medium' : 'normal' },
                React.createElement("span", { "data-testid": "timestamp" }, timestamp))),
        React.createElement("div", { className: "flex-1 py-1" },
            name && (React.createElement(React.Fragment, null,
                React.createElement(BtwText, { asChild: true, leading: "normal", size: "13", variant: nameVariant, weight: "semibold" },
                    React.createElement("span", null, name)),
                React.createElement(BtwText, { asChild: true, leading: "normal", size: "13" },
                    React.createElement("span", null, ": ")))),
            React.createElement(BtwText, { asChild: true, leading: "normal", size: "13", variant: isActive ? 'primary' : 'secondary', weight: isActive ? 'medium' : 'normal' },
                React.createElement("span", { "data-testid": "text" }, text))),
        React.createElement(Pin.Tr, { className: cn('p-2.5 opacity-0 group-hover:opacity-100', isScrollable ? 'mr-2.5' : 'mr-0') },
            React.createElement(CopyToClipboardButton, { size: "sm", text: text, testId: "copy-segment" }))));
}
function TranscriptionTab(props) {
    const { children } = props;
    const isGeneratingTranscription = false;
    return (React.createElement(BtwTabs.Content
    // height is 100%, minus the h-4 spacers top and bottom in the Root component.
    // Without this, the container will always have a scroll bar, because
    // it's effectively 100% + h-4 * 2
    , { 
        // height is 100%, minus the h-4 spacers top and bottom in the Root component.
        // Without this, the container will always have a scroll bar, because
        // it's effectively 100% + h-4 * 2
        className: "h-[calc(100%_-_32px)] w-full flex-1", value: TAB.Transcription },
        React.createElement(Stack, { className: "h-full", width: "full" }, isGeneratingTranscription ? (React.createElement(Stack, { justify: "center", align: "center", className: "h-full rounded-lg border border-dashed border-gray-600/20", width: "full" },
            React.createElement("div", { className: "max-w-72 p-8" },
                React.createElement(CtaMessage.Root, { size: "sm" },
                    React.createElement(CtaMessage.Header, { icon: React.createElement(AiTextIcon, null) },
                        React.createElement(CtaMessage.Heading, null, "Generating transcription"),
                        React.createElement(CtaMessage.Description, null, "Transcription is currently being generated, it\u2019ll appear here soon")))))) : (children))));
}
function SummaryTab(props) {
    const { children } = props;
    return (React.createElement(BtwTabs.Content, { className: "w-full [&>div]:w-full [&>div]:border [&>div]:border-purple-300", value: TAB.Summary }, children));
}
export const Transcription = {
    Item,
    Root,
    SummaryTab,
    TranscriptionTab,
};
