import { __rest } from "tslib";
import { Slot } from '@radix-ui/react-slot';
import React, { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { BtwText } from './BtwText';
import { cn } from './utils';
const root = tv({
    base: [
        // Basic root layout
        'flex',
        'flex-col',
        'relative',
        'items-start',
        // Controlling leading and trailing icons
        '[&>svg]:top-1/2',
        '[&>svg]:absolute',
        '[&>svg]:-translate-y-1/2',
        '[&>svg]:pointer-events-none',
        // Disabled state
        '[&>div>input:disabled]:cursor-not-allowed',
        '[&>div>textarea:disabled]:cursor-not-allowed',
        // Typography
        '[&>div>input]:font-body',
        '[&>div>textarea]:font-body',
    ],
    variants: {
        size: {
            intermediate: [
                // Spacing the input composition elements
                'gap-2',
                // Label sizing
                `[&>label]:text-13`,
                // Input sizing
                '[&>div>input]:h-[34px]', // To match the Button, which has 1px extra top/bottom
                '[&>div>input]:pl-2.5',
                '[&>div>input]:pr-2.5',
                '[&>div>input]:py-0',
                '[&>div>input]:rounded-md',
                '[&>div>input]:text-[13px]',
                // Textarea sizing
                '[&>div>textarea]:pl-2.5',
                '[&>div>textarea]:pr-2.5',
                '[&>div>textarea]:py-2',
                '[&>div>textarea]:rounded-md',
                '[&>div>textarea]:text-[13px]',
                // Icon sizing
                '[&>svg]:h-4',
                '[&>svg]:w-4',
            ],
            base: [
                // Spacing the input composition elements
                'gap-2',
                // Label sizing
                `[&>label]:text-sm`,
                // Input sizing
                '[&>div>input]:h-[38px]', // To match the Button, which has 1px extra top/bottom
                '[&>div>input]:pl-3',
                '[&>div>input]:pr-3',
                '[&>div>input]:py-0',
                '[&>div>input]:rounded-md',
                '[&>div>input]:text-sm',
                // Textarea sizing
                '[&>div>textarea]:pl-3',
                '[&>div>textarea]:pr-3',
                '[&>div>textarea]:py-2',
                '[&>div>textarea]:rounded-md',
                '[&>div>textarea]:text-sm',
                // Icon sizing
                '[&>svg]:h-5',
                '[&>svg]:w-5',
            ],
        },
        variant: {
            primary: [
                // Default state
                '[&>div>input]:shadow-sm',
                '[&>div>input]:text-gray-900',
                '[&>div>input]:border-gray-600/20',
                '[&>div>input]:hover:border-gray-600/40',
                '[&>div>input]:placeholder:text-gray-500',
                '[&>div>textarea]:shadow-sm',
                '[&>div>textarea]:text-gray-900',
                '[&>div>textarea]:border-gray-600/20',
                '[&>div>textarea]:hover:border-gray-600/40',
                '[&>div>textarea]:placeholder:text-gray-500',
                // Focus
                'focus:[&>div>input]:border-gray-600',
                'focus:[&>div>input]:outline-none',
                'focus:[&>div>input]:ring-[3px]',
                'focus:[&>div>input]:ring-gray-300',
                'focus:[&>div>textarea]:border-gray-600',
                'focus:[&>div>textarea]:outline-none',
                'focus:[&>div>textarea]:ring-[3px]',
                'focus:[&>div>textarea]:ring-gray-300',
                // Disabled
                '[&>div>input:disabled]:text-gray-400',
                '[&>div>input:disabled]:placeholder:text-gray-400',
                '[&>div>input:disabled]:border-gray-600/10',
                '[&>div>input:disabled]:hover:border-gray-600/10',
                '[&>div>textarea:disabled]:text-gray-400',
                '[&>div>textarea:disabled]:placeholder:text-gray-400',
                '[&>div>textarea:disabled]:border-gray-600/10',
                '[&>div>textarea:disabled]:hover:border-gray-600/10',
            ],
        },
        leadingIcon: {
            true: [],
            false: [],
        },
        trailingIcon: {
            true: [],
            false: [],
        },
        width: {
            full: [
                'w-full',
                '[&>div]:w-full',
                '[&>div>input]:w-full',
                '[&>div>textarea]:w-full',
            ],
        },
    },
    compoundVariants: [
        {
            size: 'intermediate',
            leadingIcon: true,
            className: [
                '[&>svg]:left-2',
                '[&>div>input]:pl-8',
                '[&>div>textarea]:pl-8',
            ],
        },
        {
            size: 'base',
            leadingIcon: true,
            className: [
                '[&>svg]:left-2',
                '[&>div>input]:pl-8',
                '[&>div>textarea]:pl-8',
            ],
        },
        {
            size: 'base',
            trailingIcon: true,
            className: [
                '[&>svg]:right-2',
                '[&>div>input]:pr-8',
                '[&>div>textarea]:pr-8',
            ],
        },
        {
            size: 'intermediate',
            trailingIcon: true,
            className: [
                '[&>svg]:right-2',
                '[&>div>input]:pr-8',
                '[&>div>textarea]:pr-8',
            ],
        },
    ],
    defaultVariants: {
        size: 'base',
        variant: 'primary',
    },
});
const field = tv({
    base: [
        // Basic reset
        'appearance-none',
        'bg-transparent',
        'block',
        'box-border',
        'flex-1',
        'no-underline',
        'w-full',
        'border',
        'bg-white',
        'select-none',
        // Typography
        'font-regular',
        'text-ellipsis',
        'tracking-normal',
        // Transition
        'duration-300',
        'ease-smooth',
        'transition-combined',
    ],
});
function Root(props) {
    const { children, className, size, variant, width, leadingIcon, trailingIcon } = props, rest = __rest(props, ["children", "className", "size", "variant", "width", "leadingIcon", "trailingIcon"]);
    return (React.createElement("div", Object.assign({ className: root({
            className,
            size,
            variant,
            width,
            leadingIcon: Boolean(leadingIcon),
            trailingIcon: Boolean(trailingIcon),
        }) }, rest),
        leadingIcon,
        children,
        trailingIcon));
}
const Field = forwardRef(function Field(props, ref) {
    const { className, asChild, children } = props, rest = __rest(props, ["className", "asChild", "children"]);
    if (asChild) {
        return (React.createElement("div", { className: "w-full" },
            React.createElement(Slot, Object.assign({ className: field({ className, component: 'input' }), ref: ref }, rest), children)));
    }
    return (React.createElement("div", { className: "w-full" },
        React.createElement("input", Object.assign({ className: field({ className, component: 'input' }), ref: ref }, rest))));
});
const Textarea = forwardRef(function Textarea(props, ref) {
    const { className } = props, rest = __rest(props, ["className"]);
    return (React.createElement("div", { className: "w-full" },
        React.createElement("textarea", Object.assign({ className: field({ className, component: 'textarea' }), ref: ref }, rest))));
});
function Label(props) {
    const { className, children, htmlFor } = props, rest = __rest(props, ["className", "children", "htmlFor"]);
    // TODO: This rule is deprecated. It was replaced by jsx-a11y/label-has-associated-control
    // https://github.com/devsenexx/eslint-plugin-jsx-a11y/blob/main/docs/rules/label-has-associated-control.md
    /* eslint-disable jsx-a11y/label-has-for */
    return (React.createElement(BtwText, Object.assign({ asChild: true, className: cn('flex', 'flex-col', className), variant: "primary", weight: "medium" }, rest),
        React.createElement("label", { htmlFor: htmlFor }, children)));
}
function Error(props) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (React.createElement(BtwText, Object.assign({ leading: "relaxed", asChild: true, size: "xs", variant: "red" }, rest),
        React.createElement("p", null, children)));
}
function Info(props) {
    const { children } = props, rest = __rest(props, ["children"]);
    return (React.createElement(BtwText, Object.assign({ leading: "relaxed", asChild: true, size: "xs" }, rest),
        React.createElement("p", null, children)));
}
const BtwInput = {
    Error,
    Field,
    Info,
    Label,
    Root,
    Textarea,
};
export { BtwInput };
